import * as React from "react";
import ArticleLayout from "../articles/article-layout";
import copyList from "./copyList";
import References from "../global/references.js";

export const Content = (props) => {
  return (
    <div className={`${props.show ? 'd-block' : 'd-none'}`}>
      <ArticleLayout
        page={props.page}
        copyList={copyList}
        ariaLabel="A woman, Dazon, sitting in a room full of people"
        videoStillAlt="Dazon holding the hand of a woman as they walk"
        subheading="Empowering Black Women in the American South to Protect Their Sexual Health"
        heading="DÁZON DIXON DIALLO, SISTERLOVE, INC, AND HIV PREVENTION &amp; TREATMENT"
      />
      <References page={props.page} />
    </div>
  )
}

